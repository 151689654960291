import React, {useState} from "react";
import {useCreatePartner} from "../../../features/partners/hooks/partnersHooks";
import {CreatePartnerType} from "../../../features/partners/types/CreatePartnerType";

const CreatePartner = () => {

  const [name, setName] = useState("");
  const handleNameChange = (value: string) => setName(value);

  const [externalUrl, setExternalUrl] = useState("");
  const handleExternalUrlChange = (value: string) => setExternalUrl(value);

  const [shortDescription, setShortDescription] = useState("");
  const handleShortDescriptionChange = (value: string) => setShortDescription(value);

  const [description, setDescription] = useState("");
  const handleDescriptionChange = (value: string) => setDescription(value);

  const createPartnerType: CreatePartnerType = {name: name, shortDescription: shortDescription, description: description, externalUrl: externalUrl}
  const createPartner = useCreatePartner(createPartnerType);
  const handleCreatePartner = () => {
    setName("");
    setDescription("");
    setShortDescription("");
    setExternalUrl("");
    createPartner.mutate();
  }
  const {isLoading} = createPartner;

  return (
    <div>
      <div className="mt-3">
        <label className="block text-xs font-medium text-gray-700">Име</label>
        <input type="email" value={name} onChange={(e) => handleNameChange(e.target.value)}
               className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
      </div>

      <div className="mt-3">
        <label className="block text-xs font-medium text-gray-700">Препратка към външен адрес (линк)</label>
        <input type="email" value={externalUrl} onChange={(e) => handleExternalUrlChange(e.target.value)}
               className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
      </div>

      <div className="mt-3">
        <label className="block text-xs font-medium text-gray-700">Кратко описание</label>
        <textarea rows={2}
                  value={shortDescription} onChange={(e) => handleShortDescriptionChange(e.target.value)}
                  className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
      </div>

      <div className="mt-2">
        <label className="block text-xs font-medium text-gray-700">Дълго описание</label>
        <textarea rows={4}
                  value={description} onChange={(e) => handleDescriptionChange(e.target.value)}
                  className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
      </div>

      <div className="mt-1">
        {!isLoading &&
          <button type="button" onClick={handleCreatePartner}
            className="bg-orange-400 py-1.5 px-3 rounded text-xs text-white hover:bg-orange-300 transition">
            Запис
          </button>
        }
      </div>
    </div>
  )
}

export default CreatePartner;