import React from 'react';
import {Link} from "react-router-dom";

const Logo = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="70" viewBox="0 0 300 30">
          <text x="40" y="28" fontSize="24" fontWeight="bold">
              <tspan fill="#ff8500" fontSize="48">&#8730;</tspan>
              <tspan fill="black" dx="4" fontSize="48">9</tspan>
          </text>
          <text x="110" y="22" fontSize="26" fontWeight="bold">
              <tspan fill="#ff8500" dx="4" fontSize="28">Math</tspan>
              <tspan fill="black" fontSize="28">еlandia</tspan>
          </text>
      </svg>
    );
};

export default Logo;