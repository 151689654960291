import {useQueryClient} from "react-query";
import {info} from "../../../services/notify";
import {useMutation, useQuery} from "../../../hooks/common";
import {EventType} from "../types/EventType";
import eventService from "../services/eventService";

export const useCreateEvent = (eventType: EventType) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries("events");
    info();
  };
  return useMutation(() => eventService.createEvent(eventType), onSuccess)
}

export const useGetEventsByMonth = (month: string) => {
  console.log(month)
  return useMutation(() => eventService.getEventsByMonth(month))
}

export const useUpdateEvent = (eventType: EventType) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries("events");
    info();
  };
  return useMutation(() => eventService.updateEvent(eventType), onSuccess)
}

export const useDeleteEvent = (eventId: string) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries("events");
    info();
  };
  return useMutation(() => eventService.deleteEvent(eventId), onSuccess)
}

export const useGetEvents = () => {
  return useQuery({
    key: "events",
    query: () => eventService.getEvents()
  });
}
