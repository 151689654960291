import {lazy} from "react";
import {RouteObject} from "react-router";
import MainLayout from "./layouts/MainLayout";
import AdminLoginLayout from "./layouts/AdminLoginLayout";
import AdminLogin from "./pages/admin/AdminLogin";
import Dashboard from "./pages/admin/Dashboard";
import AdminLayout from "./layouts/AdminLayout";
import AdminMembers from "./pages/admin/team/AdminMembers";
import EditMember from "./pages/admin/team/EditMember";
import CreateMember from "./pages/admin/team/CreateMember";
import {getWebStorage} from "./services/webStorage";
import AdminPartners from "./pages/admin/partners/AdminPartners";
import AdminEvents from "./pages/admin/events/AdminEvents";

const About = lazy(() => import("./pages/About"));
const Team = lazy(() => import("./pages/Team"));
const Calendar = lazy(() => import("./pages/Calendar"));
const Contact = lazy(() => import("./pages/Contact"));
const Pricing = lazy(() => import("./pages/Pricing"));
const Partners = lazy(() => import("./pages/Partners"))

// Services
const Services = lazy(() => import("./pages/admin/service2/Services"));
const CreateService = lazy(() => import("./pages/admin/service2/CreateService"));
const EditService = lazy(() => import("./pages/admin/service2/EditService"));
const AdminServices = lazy(() => import("./pages/admin/service2/AdminServices"));


export const routes: RouteObject[] = [
  {
    element: <MainLayout/>,
    children: [
      {
        path: "/",
        element: <About/>,
      },
      {
        path: "/team",
        element: <Team/>,
      },
      {
        path: "/calendar",
        element: <Calendar/>,
      },
      {
        path: "/contact",
        element: <Contact/>,
      },
      {
        path: "/pricing",
        element: <Pricing/>,
      },
      {
        path: "/partners",
        element: <Partners/>,
      },
    ],
  },

  // Admin login
  {
    element: <AdminLoginLayout/>,
    children: [
      {
        path: "/mth/login",
        element: <AdminLogin/>
      }
    ]
  },

  // Admin panel
  getWebStorage("token") ? {
    element: <AdminLayout/>,
    children: [
      {
        path: "/mth/admin/",
        element: <Dashboard/>
      },

      // Dashboard
      {
        path: "/mth/admin/dashboard",
        element: <Dashboard/>
      },

      // Services
      {
        path: "/mth/admin/services",
        element: <AdminServices/>
      },

      // Members
      {
        path: "/mth/admin/members",
        element: <AdminMembers/>
      },
      {
        path: "/mth/admin/members/edit/:id",
        element: <EditMember/>
      },
      {
        path: "/mth/admin/members/create",
        element: <CreateMember/>
      },

      // Partners
      {
        path: "/mth/admin/partners",
        element: <AdminPartners/>
      },

      // Calendar
      {
        path: "/mth/admin/calendar",
        element: <AdminEvents/>
      }
    ]
  } : {},
  {
    path: "*",
    element: <div>Work in progress...</div>,
  },
];