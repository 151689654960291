import Events from "./Events";
import CreateEvent from "./CreateEvent";
import EditEvent from "./EditEvent";

const AdminEvents = () => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="bg-white m-2 p-2 rounded">
          <div className="text-gray-600 text-sm text-center tracking-wider">
            Списък
          </div>
          <div className="mt-3">
            <Events/>
          </div>
        </div>
        <div className="bg-white m-2 p-2 rounded">
          <div className="text-gray-600 text-sm text-center tracking-wider">
            Преглед/Редакция
          </div>
          <div className="mt-3">
            <EditEvent/>
          </div>
        </div>
        <div className="bg-white m-2 p-2 rounded">
          <div className="text-gray-600 text-sm text-center tracking-wider">
            Създай нов
          </div>
          <div className="mt-3">
            <CreateEvent/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminEvents;