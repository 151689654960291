import api from "../../../lib/api";
import {ENDPOINTS} from "../../../data/endpoints";
import {EventType} from "../types/EventType";

const createEvent = async (eventType: EventType) => {
  await api.post(ENDPOINTS.EVENTS, eventType);
}

const getEventsByMonth = async (month: string) => {
  await api.post(ENDPOINTS.EVENTS + "/month", month);
}

const updateEvent = async (eventType: EventType) => {
  await api.patch(ENDPOINTS.EVENTS + "/" + eventType.id, eventType);
}

const deleteEvent = async (eventId: string) => {
  await api.delete(ENDPOINTS.EVENTS + "/" + eventId);
}

const getEvents = async () => {
  return await api.get<EventType[]>(ENDPOINTS.EVENTS);
}

const eventService = {
  createEvent,
  updateEvent,
  deleteEvent,
  getEvents,
  getEventsByMonth
};

export default eventService;
