import {createStore} from "../../../lib/store";
import {EventType} from "../types/EventType";

export interface IEventStore {
  selectedEvent: EventType | null,
  setSelectedEvent: (value: EventType | null) => void;
}

export const useEventStore = createStore("event_store", (set, get) => ({
    selectedEvent: null,
    setSelectedEvent: (event: EventType | null) => set((state: any) => ({selectedEvent: event?.id === state.selectedEvent?.id ? null : event})),
  })
)