import {ENDPOINTS} from "../../../data/endpoints";
import api from "../../../lib/api";

const authentication = async (email: string, password: string) => {
  return api.post(ENDPOINTS.AUTHENTICATION, {email: email, password: password});
}

const authService = {
  authentication
};

export default authService;