import {useRef} from "react";
import {useMutation} from "react-query";
import authService from "../../features/authentication/services/authService";
import {error} from "../../services/notify";
import {addWebStorage} from "../../services/webStorage";
import {useNavigate} from "react-router-dom";

const AdminLoginLayout = () => {

  const navigate = useNavigate();

  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const authenticationQuery = useMutation({
    mutationFn: (data: ({ email: string, password: string })) => authService.authentication(data?.email, data.password),
    onSuccess: (data: any) => {
      addWebStorage("token", data.token);
      navigate("/mth/admin")
    },
    onError: () => error("Възникна проблем, моля свържете се с администратор")
  })

  const handleSubmit = () => {
    if (email.current && password.current) {
      const credentials = {
        email: email.current.value,
        password: password.current.value
      }
      authenticationQuery.mutate(credentials);
    }
  };

  return (
    <div className="w-full max-w-xs mx-auto py-8 flex items-center justify-center h-screen">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="pb-4 text-center text-orange-400 uppercase font-extrabold tracking-wider">
          АДМИН ПАНЕЛ
        </div>
        <hr/>

        {/*Email*/}
        <div className="mb-4 mt-4">
          <label className="block text-orange-400 font-bold mb-2"
                 htmlFor="email">
            Имейл
          </label>
          <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                 id="email"
                 type="email"
                 placeholder="Имейл"
                 ref={email}/>
        </div>

        {/*Password*/}
        <div className="mb-6">
          <label className="block text-orange-400 font-bold mb-2"
                 htmlFor="password">
            Парола
          </label>
          <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                 id="password"
                 type="password"
                 placeholder="Парола"
                 ref={password}/>
        </div>

        {/*Button*/}
        <button type="button"
                onClick={handleSubmit}
                className="text-white bg-orange-400 hover:bg-orange-600 px-4 py-2 rounded-xl transition-all">
          Вход
        </button>
      </form>
    </div>
  );
};

export default AdminLoginLayout;