import React, {FC} from "react";
import {Link} from "react-router-dom";

interface Props {
  title: string;
  disabled?: boolean;
  navigateTo?: string;
  onClick?: () => void;
}

const AdminButton: FC<Props> = ({title, disabled, navigateTo, onClick}) => {
  if (navigateTo === undefined) navigateTo = "#";
  return (
    <>
      <Link to={disabled ? '#' : navigateTo}
            onClick={onClick}
            className={"ml-2 p-2 inline-flex tracking-wider font-semibold text-xs rounded text-white transition-all " + (disabled ? 'bg-orange-200' : 'bg-orange-400 hover:bg-orange-300')}>
        {title}
      </Link>
    </>
  );
};

export default AdminButton;