import {useGetEvents} from "../../../features/event/hooks/eventHooks";
import {EventType} from "../../../features/event/types/EventType";
import {useEventStore} from "../../../features/event/stores/eventStore";
import dayjs from "dayjs";

const Events = () => {

  const eventQuery = useGetEvents();
  const selectedEvent = useEventStore((state: any) => state.selectedEvent);
  const setSelectedEvent = useEventStore((state: any) => state.setSelectedEvent);

  return (
    <div>
      <div>
        <table className="w-full">
          <thead>
          <tr>
            <td className="text-xs px-2 py-1 text-orange-600">Име</td>
            <td className="text-xs px-2 py-1 text-orange-600">Описание</td>
            <td className="text-xs px-2 py-1 text-orange-600">Старт</td>
            <td className="text-xs px-2 py-1 text-orange-600">Край</td>
          </tr>
          </thead>
          <tbody>
          {eventQuery.data?.map((element: EventType) => (
            <tr key={element.id}
                onClick={() => setSelectedEvent(element)}
                className={"hover:bg-gray-50 hover:cursor-pointer" + (selectedEvent?.id === element?.id ? " bg-gray-50" : "")}>
              <td className="text-xs px-2 py-1 text-gray-600">{element.name}</td>
              <td className="text-xs px-2 py-1 text-gray-600">{element.description}</td>
              <td className="text-xs px-2 py-1 text-gray-600">{dayjs(element.startDate).format('DD MMMM YYYY')}</td>
              <td className="text-xs px-2 py-1 text-gray-600">{dayjs(element.endDate).format('DD MMMM YYYY')}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Events;