import {useChangePosition} from "../../features/member/hooks/memberHooks";
import React from "react";

interface Props {
  memberId: string;
}

export const ChangeTeamPosition: React.FC<Props> = ({memberId}) => {

  const useUp = useChangePosition(memberId, "UP");
  const useDown = useChangePosition(memberId, "DOWN");

  const handleUp = () => {
    useUp.mutate();
  }

  const handleDown = () => {
    useDown.mutate();
  }

  return (
    <div className="flex">
      <div className="cursor-pointer" onClick={handleDown}>
        <i className="pi pi-arrow-circle-left text-orange-600 hover:text-orange-300 transition"/>
      </div>
      <div className="cursor-pointer ml-2 " onClick={handleUp}>
        <i className="pi pi-arrow-circle-right text-orange-600 hover:text-orange-300 transition"/>
      </div>
    </div>
  )
}

export default ChangeTeamPosition