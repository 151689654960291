import {CreatePartnerType} from "../types/CreatePartnerType";
import {info} from "../../../services/notify";
import {useMutation, useQuery} from "../../../hooks/common";
import partnersService from "../services/partnersService";
import {useQueryClient} from "react-query";
import {PartnerType} from "../types/PartnerType";

export const useCreatePartner = (createPartnerType: CreatePartnerType) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries("partners");
    info();
  };
  return useMutation(() => partnersService.createPartner(createPartnerType), onSuccess)
}

export const useGetPartners = () => {
  return useQuery({
    key: "partners",
    query: () => partnersService.getPartners()
  });
}

export const useGetPartner = (id: string) => {
  return useQuery({
    key: ["partner", id],
    query: () => partnersService.getPartner(id)
  });
}

export const useEditPartner = (partner: PartnerType) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries("partners");
    info();
  };
  return useMutation(() => partnersService.editPartner(partner), onSuccess)
}

export const useDeletePartner = (partnerId: string) => {
  const onSuccess = () => {
    queryClient.invalidateQueries("partners");
    info();
  };
  const queryClient = useQueryClient();
  return useMutation(() => partnersService.deletePartner(partnerId), onSuccess)
}