import CreatePartner from "./CreatePartner";
import Partners from "./Partners";
import EditPartner from "./EditPartner";

const AdminPartners = () => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="bg-white m-2 p-2 rounded">
          <div className="text-gray-600 text-sm text-center tracking-wider">
            Списък
          </div>
          <div className="mt-3">
            <Partners/>
          </div>
        </div>
        <div className="bg-white m-2 p-2 rounded">
          <div className="text-gray-600 text-sm text-center tracking-wider">
            Преглед/Редакция
          </div>
          <div className="mt-3">
            <EditPartner/>
          </div>
        </div>
        <div className="bg-white m-2 p-2 rounded">
          <div className="text-gray-600 text-sm text-center tracking-wider">
            Създай нов
          </div>
          <div className="mt-3">
            <CreatePartner/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminPartners;