import { initializeApp } from "firebase/app";
import {getStorage} from "@firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBsB98P5KaXnx-bZwbkUWuwvwJgp4n2OIQ",
  authDomain: "yels-43141.firebaseapp.com",
  projectId: "yels-43141",
  storageBucket: "yels-43141.appspot.com",
  messagingSenderId: "890960431472",
  appId: "1:890960431472:web:0b76cd9c173089250e27b5"
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
