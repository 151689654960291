import {FC, useState} from "react";
import {Link} from "react-router-dom";

interface Props {
  to: string;
  label: string;
}

const NavbarDropdownItem: FC<Props> = ({label, to}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="relative items-center flex cursor-pointer"
         onMouseLeave={() => setShowDropdown(false)}
         onMouseEnter={() => setShowDropdown(true)}>

      <div className="px-2 text-lg font-extrabold hover:text-orange-400 transition-all">
        <div>
          {label}
        </div>
      </div>

      {showDropdown && (
        <div className="absolute top-10 pt-10">
          <div className="bg-white rounded-lg shadow-xl py-2">
            <Link to="/team"
               className="block px-4 py-2 text-gray-800 hover:text-orange-400 whitespace-nowrap font-semibold">
              Екип
            </Link>
            <Link to="/partners"
               className="block px-4 py-2 text-gray-800 hover:text-orange-400 whitespace-nowrap font-semibold">
              Партньори
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarDropdownItem;