export const ENDPOINTS = {
  // Authentication & Authorization
  AUTHENTICATION: "/user/login",

  // Member
  MEMBER: "/member",
  VALIDATE_MEMBER: "/member/validate",
  NEW_DETAIL: "/member/detail",
  DELETE_DETAIL: "/member/detail/delete",
  UPDATE_MEMBER: "/member/update",
  CREATE_MEMBER: "/member/create",
  DELETE_MEMBER: "/member/delete",
  UPLOAD_PHOTO: "/member/photo",
  CHANGE_POSITION: "/member/pos/",
  CHANGE_DETAIL_POSITION: "/member/pos/det/",

  // Services
  GET_SERVICES: "/service",
  GET_ONLINE_SERVICES: "/service/online",
  GET_NORMAL_SERVICES: "/service/normal",
  CREATE_SERVICE: "/service",
  UPDATE_SERVICE: "/service/update",
  ADD_DETAIL: "/service/detail",
  GET_SERVICE_DETAILS: "/service/detail",
  DELETE_SERVICE_DETAIL: "/service/detail/delete",
  DELETE_SERVICE: "/service/delete",
  MOVE_DETAIL: "/service/move",

  // Partners
  PARTNER: "/partner",

  // Events
  EVENTS: "/event",

  // Services
  SERVICES: "/service"
};
