import React, {useRef, useState} from "react";
import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../../lib/firebase";
import axios from "axios";
import {info} from "../../../services/notify";
import {useQueryClient} from "react-query";

interface Props {
  memberId: string;
}

export const UploadPhotoMember: React.FC<Props> = ({memberId}) => {

  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState<boolean>(false);
  const fileUploader = useRef<FileUpload>(null);

  const uploadHandler = async (event: FileUploadHandlerEvent) => {
    setUploadProgress(true);
    const uploadTasks = event.files.map((file) => {
      const {v4: uuidv4} = require('uuid');
      const uuid = uuidv4();
      const fileExtension = file.name.split('.').pop();

      const storageRef = ref(storage, `/images/${uuid}.${fileExtension}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise<string>((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          },
          (error) => reject(error),
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => resolve(url))
              .catch((error) => reject(error));
          }
        );
      });
    });

    await Promise.all(uploadTasks)
                 .then((e) => saveImages(e))
                 .then(() => fileUploader.current!.clear())
                 .then(() => info("Файловете са качени."))
                 .then(() => setUploadProgress(false))
                 .then(() => window.location.reload());
  }

  const saveImages = async (imageUrls: string[]) => {
    const requestPayload = {
      memberId: memberId,
      url: imageUrls[0],
    };

    console.log(requestPayload)

    await axios.post("https://mathelandia.com:8080/api/v1/member/photo", requestPayload)
  };

  return (
    <div>
      {uploadProgress && 'uploading'}
      <FileUpload ref={fileUploader}
                  mode={"advanced"}
                  accept="image/*"
                  auto={true}
                  multiple={true}
                  customUpload={true}
                  uploadHandler={uploadHandler}
                  maxFileSize={1000000}/>
    </div>
  )
}

export default UploadPhotoMember;