import {FC} from "react";
import {Link} from "react-router-dom";

interface Props {
  to: string;
  label: string;
  classOverride?: string;
  onClick?: () => void;
}

const NavbarItem: FC<Props> = ({label, to, classOverride, onClick}) => {
  const defaultClasses = "items-center flex px-2 text-lg font-extrabold hover:text-orange-400 transition-all";

  return (
    <Link to={to} className={classOverride ? classOverride : defaultClasses} onClick={onClick}>
      {label}
    </Link>
  );
};

export default NavbarItem;
