import React, {useRef} from "react";
import {useMutation} from "react-query";
import memberService from "../../../features/member/services/memberService";
import {MemberType} from "../../../features/member/types/memberType";
import {error, info} from "../../../services/notify";
import {useNavigate} from "react-router-dom";

const EditMember = () => {

  const navigate = useNavigate();

  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);
  const description = useRef<HTMLTextAreaElement>(null);

  const createMemberQuery = useMutation({
    mutationFn: (member: MemberType) => memberService.createMember(member),
    onSuccess: () => {
      navigate("/mth/admin/members")
      info("Member created successfully");
    }
  })

  const handleCreateMember = () => {
    if (firstName.current !== null && lastName.current !== null && description.current !== null) {
      const member: MemberType = {
        firstName: firstName.current.value,
        lastName: lastName.current.value,
        description: description.current.value
      };

      createMemberQuery.mutate(member);
    } else {
      error("Required field!");
    }
  }

  return (
    <>
      <div className="bg-white p-5 text-sm">

        <span className="font-semibold tracking-wider">Основни данни</span>
        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Име</label>
          <input type="email" ref={firstName}
                 className="mt-1 w-full md:w-1/3 rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Last name</label>
          <input type="email" ref={lastName}
                 className="mt-1 w-full md:w-1/3 rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Описание</label>
          <textarea rows={10} ref={description}
                    className="mt-1 w-full md:w-1/3 rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-2">
          <button onClick={() => handleCreateMember()}
                  className="bg-orange-400 py-1.5 px-3 rounded text-xs text-white hover:bg-orange-300 transition">
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default EditMember;