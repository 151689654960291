import {useMutation, useQuery} from "../../../hooks/common";
import memberService from "../services/memberService";
import {NewMemberDetailType} from "../types/memberDetailsType";
import {useQueryClient} from "react-query";

export const useMembers = () => {
  const {data, isLoading, isError} = useQuery({
    key: 'TEAM_QUERY_KEY',
    query: () => memberService.getAll(),
  });

  return {data, isLoading, isError};
};

export const useValidateMember = (id: string) => {
  const {data, isLoading, isError} = useQuery({
    key: 'VALIDATE_MEMBER_ID',
    query: () => memberService.validateMember(id)
  })

  return {data, isLoading, isError};
}

export const useGetMember = (id: string) => {
  const {data, isLoading, isError} = useQuery({
    key: ['GET_MEMBER', id],
    query: () => memberService.getMember(id)
  })

  return {data, isLoading, isError};
}

export const useGetMemberDetails = (id: string) => {
  const {data, isLoading, isError} = useQuery({
      key: ['GET_MEMBER_DETAILS', id],
      query: () => memberService.getMemberDetails(id)
    }
  );

  return {data, isLoading, isError};
}

export const useDeleteDetail = (id: string) => {
  return useMutation(() => memberService.deleteDetail(id));
}

export const useAddMemberDetail = (memberDetail: NewMemberDetailType, onSuccess: any) => {
  return useMutation(() => memberService.addDetail(memberDetail), onSuccess);
}

export const useDeleteMemberDetail = (id: string, onSuccess: any) => {
  return useMutation(() => memberService.deleteDetail(id), onSuccess);
}

export const useChangePosition = (id: string, pos: string) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries("TEAM_QUERY_KEY");
  }
  return useMutation(() => memberService.changePosition(id, pos), onSuccess);
}

export const useChangeDetailPosition = (id: string, pos: string, memberId: string) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries(['memberDetails', memberId]);
  }
  return useMutation(() => memberService.changeDetailPosition(id, pos), onSuccess);
}