import {Link} from "react-router-dom";
import React from "react";

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font bg-white mt-24">
      <div className="container px-5 py-8 mx-auto">
        <div className="flex flex-wrap md:text-left text-center order-first">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="font-bold text-orange-400 tracking-widest mb-3">Контакти</h2>
            <div className="mb-10 text-sm">
              <p className="tracking-wider">Адрес: бул. Христо Ботев 71,  гр. София 1000</p>
              <p className="tracking-wider">Телефон: +359 889 673 642</p>
              <p className="tracking-wider">Имейл: <span className="text-orange-400 font-semibold">mathelandiabg@gmail.com</span></p>
            </div>
          </div>
          {/*<div className="lg:w-1/4 md:w-1/2 w-full px-4">*/}
          {/*  <h2 className="font-bold text-orange-400 tracking-widest mb-3">Полезни връзки</h2>*/}
          {/*  <nav className="list-none mb-10 text-sm">*/}
          {/*    <li>*/}
          {/*      <a className="text-gray-600 hover:text-gray-800 cursor-pointer">Защита на лични данни</a>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <a className="text-gray-600 hover:text-gray-800 cursor-pointer">Общи условия</a>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <a className="text-gray-600 hover:text-gray-800 cursor-pointer">Използване на бисквитки</a>*/}
          {/*    </li>*/}
          {/*  </nav>*/}
          {/*</div>*/}
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="font-bold text-orange-400 tracking-widest mb-3">Партньори</h2>
            <nav className="list-none mb-10 text-sm">
              <li>
                <a className="text-gray-600 hover:text-gray-800 tracking-wider">Brain Academy</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800 tracking-wider">Читалище „Добри Чинтулов“</a>
              </li>
            </nav>
          </div>
          <div className="lg:w-2/4 md:w-1/2 w-full px-4">
            <section className="map">
              <iframe src="https://www.google.com/maps/d/embed?mid=1v_x-xPL0KOcSrjLH4DR-v8AyPR0CjEM&ehbc=2E312F"
                      frameBorder="0"
                      className="rounded-xl"
                      width="100%"
                      height="280">
              </iframe>
            </section>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            {/*<h2 className="font-bold text-orange-400 tracking-widest mb-3">Абонирайте се за нашия бюлетин</h2>*/}
            {/*<div className="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">*/}
            {/*  <div className="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">*/}
            {/*    <label htmlFor="footer-field" className="leading-7 text-sm text-gray-600">Имейл</label>*/}
            {/*    <input type="text" id="footer-field" name="footer-field"*/}
            {/*           className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>*/}
            {/*  </div>*/}
            {/*  <button*/}
            {/*    className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-white bg-orange-400 border-0 py-2 px-6 focus:outline-none hover:bg-orange-600 rounded">*/}
            {/*    Абонирай се*/}
            {/*  </button>*/}
            {/*</div>*/}
            {/*<p className="text-gray-500 text-xs mt-2 md:text-left text-center ">*/}
            {/*  Вашият имейл адрес няма да бъде споделен с трети лица.*/}
            {/*</p>*/}
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
          <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2020 Мателандия</p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        <Link to="https://www.facebook.com/profile.php?id=100064276711859" className="text-orange-400 cursor-pointer">
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"/>
          </svg>
        </Link>
        {/*<Link to="/" className="ml-3 text-orange-400 cursor-pointer">*/}
        {/*  <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">*/}
        {/*    <path*/}
        {/*      d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"/>*/}
        {/*  </svg>*/}
        {/*</Link>*/}
        <Link to="https://www.instagram.com/mathelandia" className="ml-3 text-orange-400 cursor-pointer">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"/>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"/>
          </svg>
        </Link>
        {/*<Link to="" className="ml-3 text-orange-400 cursor-pointer">*/}
        {/*  <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">*/}
        {/*    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"/>*/}
        {/*    <circle cx="4" cy="4" r="2" stroke="none"/>*/}
        {/*  </svg>*/}
        {/*</Link>*/}
            <Link to="https://www.youtube.com/@mathelandia"
                  target="_blank"
                  className="ml-4 text-orange-400 cursor-pointer">
                  <i className="pi pi-youtube"/>
            </Link>
      </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;