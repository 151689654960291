import axios, { AxiosInstance, AxiosResponse } from "axios";
import { getWebStorage, removeWebStorage } from "../services/webStorage";
import {redirect} from "react-router-dom";

interface IApiService {
  get<T>(url: string): Promise<T>;
  post<T, R>(url: string, data?: R): Promise<T>;
  put<T, R>(url: string, data?: R): Promise<T>;
  patch<T, R>(url: string, data?: R): Promise<T>;
  delete<T>(url: string): Promise<T>;
}

const api: IApiService = (() => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: "https://mathelandia.com:8080/api/v1",
  });

  axiosInstance.interceptors.request.use(
    config => {
      const authToken = getWebStorage("token");
      if (authToken) {
        const token = getWebStorage("token");
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    error => {
      console.log(error)
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = getWebStorage("REFRESH_TOKEN");
        const email = getWebStorage("EMAIL");
        // if (refreshToken && email) {
        //   authService
        //     .refreshToken({
        //       email: email,
        //       refreshToken: refreshToken,
        //     })
        //     .then((res: { data: { result: { accessToken: any; refreshToken: any; }; }; }) => {
        //       const { accessToken, refreshToken } = res.data.result;
        //       addWebStorage("AUTH_TOKEN", accessToken);
        //       addWebStorage("REFRESH_TOKEN", refreshToken);
        //     });
        //   return axios(originalRequest);
        // }
        removeWebStorage("EMAIL");
        removeWebStorage("AUTH_TOKEN");
        removeWebStorage("REFRESH_TOKEN");
        redirect("/");
      }
      return Promise.reject(error);
    }
  );

  return {
    async get<T>(url: string): Promise<T> {
      const response: AxiosResponse<T> = await axiosInstance.get(url);
      return response.data;
    },

    async post<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.post(url, data);
      return response.data;
    },

    async put<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.put(url, data);
      return response.data;
    },

    async patch<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.patch(url, data);
      return response.data;
    },

    async delete<T>(url: string): Promise<T> {
      const response: AxiosResponse<T> = await axiosInstance.delete(url);
      return response.data;
    },
  };
})();

export default api;
