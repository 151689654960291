import {FC} from "react";
import {MemberType} from "../types/memberType";
import ChangeTeamPosition from "../../../components/team/ChangeTeamPosition";

interface Props {
  member: MemberType;
  isSelected: boolean;
  handleSelection: (id: string) => void;
}

const MemberAdmin: FC<Props> = ({member, isSelected, handleSelection}) => {
  return (
    <div className="p-2 relative" onClick={() => handleSelection(member.id!)}>
      <div className={'h-full flex items-center border-gray-200 border p-4 rounded-lg hover:cursor-pointer ' + (isSelected ? 'bg-orange-50' : '')}>
        <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={member.imageUrl}/>
        <div className="flex-grow">
          <h2 className="text-gray-900 title-font font-medium">{member.firstName + ' ' + member.lastName}</h2>
        </div>
      </div>
    </div>
  );
};

export default MemberAdmin;