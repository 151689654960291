import React, {useState} from "react";
import Datepicker from "react-tailwindcss-datepicker";
import {DateValueType} from "react-tailwindcss-datepicker/dist/types";
import {EventType} from "../../../features/event/types/EventType";
import {useCreateEvent} from "../../../features/event/hooks/eventHooks";

const CreateEvent = () => {

  const [name, setName] = useState("");
  const handleNameChange = (value: string) => setName(value);

  const [description, setDescription] = useState("");
  const handleDescriptionChange = (value: string) => setDescription(value);

  const [startDate, setStartDate] = useState<DateValueType>(null);
  const handleStartDateChange = (value: DateValueType) => setStartDate(value);

  const [endDate, setEndDate] = useState<DateValueType>(null);
  const handleEndDateChange = (value: DateValueType) => setEndDate(value);

  const createEventType: EventType = {name: name, description: description, startDate: startDate?.startDate?.toString(), endDate: endDate?.endDate?.toString()};
  const createEvent = useCreateEvent(createEventType);
  const handleCreateEvent = () => {
    createEvent.mutate();
    setName("");
    setDescription("");
    setStartDate(null);
    setEndDate(null);
  }

  return (
    <div>
      <div>
        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Име</label>
          <input type="email" value={name} onChange={(e) => handleNameChange(e.target.value)}
                 className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Кратко описание</label>
          <textarea rows={2} value={description} onChange={(e) => handleDescriptionChange(e.target.value)}
                    className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-2">
          <label className="block text-xs font-medium text-gray-700">Започва от <span className="text-gray-400">(вкл.)</span></label>
          <Datepicker primaryColor={"red"}
                      value={startDate}
                      useRange={false}
                      placeholder="Старт"
                      asSingle={true}
                      startWeekOn={"mon"}
                      toggleClassName="hidden"
                      inputClassName="mt-1 w-full rounded-md border-gray-200 shadow-sm !bg-gray-100 px-3 !py-1.5 text-xs"
                      onChange={(date) => handleStartDateChange(date)}/>
        </div>

        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Завършва на <span className="text-gray-400">(вкл.)</span></label>
          <Datepicker primaryColor={"red"}
                      value={endDate}
                      useRange={false}
                      placeholder="Край"
                      asSingle={true}
                      toggleClassName="hidden"
                      startWeekOn={"mon"}
                      inputClassName="mt-1 w-full rounded-md border-gray-200 shadow-sm !bg-gray-100 px-3 !py-1.5 text-xs !focus:ring-0"
                      onChange={(date) => handleEndDateChange(date)}/>
        </div>

        <div className="mt-2">
          <button type="button" onClick={handleCreateEvent}
                  className="bg-orange-400 py-1.5 px-3  rounded text-xs text-white hover:bg-orange-300 transition ">
            Запис
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateEvent;