import React, {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {MemberDetailsType, NewMemberDetailType} from "../../../features/member/types/memberDetailsType";
import {useMutation, useQuery, useQueryClient} from "react-query";
import memberService from "../../../features/member/services/memberService";
import {MemberType} from "../../../features/member/types/memberType";
import ChangePhoto from "../../../components/team/ChangePhoto";
import {info} from "../../../services/notify";
import ChangeTeamDetailPosition from "../../../components/team/ChangeTeamDetailPosition";

const EditMember = () => {

  const params = useParams();
  const queryClient = useQueryClient();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');

  const newDetailNameRef = useRef<HTMLInputElement>(null);
  const newDetailValueRef = useRef<HTMLInputElement>(null);

  const [memberDetails, setMemberDetails] = useState<MemberDetailsType[]>([]);

  useQuery({
    queryKey: ["member", params.id!],
    queryFn: () => memberService.getMember(params.id!),
    onSuccess: data => {
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setDescription(data.description)
      setImageUrl(data.imageUrl);
    }
  });

  useQuery({
    queryKey: ["memberDetails", params.id!],
    queryFn: () => memberService.getMemberDetails(params.id!),
    onSuccess: data => {
      setMemberDetails(data)
    }
  });

  const addDetailMutation = useMutation({
    mutationFn: (memberDetail: NewMemberDetailType) => memberService.addDetail(memberDetail),
    onSuccess: () => {
      queryClient.invalidateQueries(["memberDetails", params.id!]);
      info()
    }
  })

  const addDetailProcess = () => {
    if (newDetailValueRef.current !== null && newDetailNameRef.current !== null) {
      addDetailMutation.mutate({
        memberId: params.id!,
        name: newDetailNameRef.current.value,
        value: newDetailValueRef.current.value
      })
    }
  }

  const deleteDetailQuery = useMutation({
    mutationFn: (detailId: string) => memberService.deleteDetail(detailId),
    onSuccess: () => {
      queryClient.invalidateQueries(["memberDetails", params.id!]);
      info()
    }
  })

  const deleteDetailProcess = (detailId: string) => {
    deleteDetailQuery.mutate(detailId);
  }

  const updateMemberQuery = useMutation({
    mutationFn: (member: MemberType) => memberService.updateMember(member),
    onSuccess: () => {
      queryClient.invalidateQueries(["member", params.id!]);
      info()
    }
  });

  const updateMemberProcess = () => {
    const member: MemberType = {
      id: params.id!,
      firstName: firstName,
      lastName: lastName,
      description: description
    }

    updateMemberQuery.mutate(member);
  }

  return (
    <>
      <div className="bg-white p-5 text-sm">

        <span className="font-semibold tracking-wider">Основни данни</span>
        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Име</label>
          <input type="email" value={firstName} onChange={e => setFirstName(e.target.value)}
                 className="mt-1 w-full md:w-1/3 rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Last name</label>
          <input type="email" value={lastName} onChange={e => setLastName(e.target.value)}
                 className="mt-1 w-full md:w-1/3 rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Описание</label>
          <textarea rows={10} value={description} onChange={e => setDescription(e.target.value)}
                    className="mt-1 w-full md:w-1/3 rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-2">
          <button onClick={() => updateMemberProcess()}
                  className="bg-orange-400 py-1.5 px-3 rounded text-xs text-white hover:bg-orange-300 transition">
            Запис
          </button>
        </div>

        {/*<div className="mt-6 font-semibold tracking-wider">Снимка</div>*/}
        <div className="mt-3">
          {/*<ChangePhoto memberId={params.id!} imageUrl={imageUrl}/>*/}
        </div>

        <div className="mt-6 font-semibold tracking-wider">Характеристики</div>
        <div className="mt-3">
          <p className="text-xs text-gray-600">Създай нова</p>
          <input type="email" ref={newDetailNameRef}
                 className="mt-1 w-full md:w-1/6 rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"
                 placeholder="Име"/>
          <input type="email" ref={newDetailValueRef}
                 className="ml-1 mt-1 w-full md:w-1/6 rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"
                 placeholder="Стойност"/>
          <button onClick={() => addDetailProcess()}
                  className="ml-1 bg-orange-400 py-1.5 px-3 rounded text-xs text-white hover:bg-orange-300 transition">
            Създай
          </button>
        </div>

        <div className="mt-3">
          <p className="text-xs text-gray-600">Списък</p>
          <table className="mt-2">
            <thead>
            <tr className="bg-gray-100 text-gray-400 text-xs text-left">
              <th className="px-2 py-0.5">Име</th>
              <th className="px-2 py-0.5">Стойност</th>
              <th className="px-2 py-0.5">Опции</th>
              <th className="px-2 py-0.5"/>
              <th className="px-2 py-0.5"/>
            </tr>
            </thead>
            <tbody>
            {memberDetails.map((detail: MemberDetailsType) => {
              return (
                <tr key={detail.id} className="text-xs">
                  <td className="px-2 py-0.5">{detail.detailName}</td>
                  <td className="px-2 py-0.5">{detail.detailValue}</td>
                  <td className="text-red-700">
                    <div className="justify-center flex" onClick={() => deleteDetailProcess(detail.id)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                           stroke="currentColor"
                           className="w-5 h-5 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                      </svg>
                    </div>
                  </td>
                  <td>
                    <ChangeTeamDetailPosition detail={detail.id} member={params.id!}/>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default EditMember;