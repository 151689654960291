import {useMembers} from "../../../features/member/hooks/memberHooks";
import {MemberType} from "../../../features/member/types/memberType";
import React, {useState} from "react";
import MemberAdmin from "../../../features/member/components/MemberAdmin";
import AdminButton from "../../../components/admin/AdminButton";
import {useMutation, useQueryClient} from "react-query";
import memberService from "../../../features/member/services/memberService";
import {info} from "../../../services/notify";
import ChangeTeamPosition from "../../../components/team/ChangeTeamPosition";
import UploadPhotoMember from "./UploadPhotoMember";
import {Dialog} from "primereact/dialog";

const AdminMembers = () => {

  const queryClient = useQueryClient();

  const members = useMembers();
  const [selectedMemberId, setSelectedMemberId] = useState<string>('');

  const [visible, setVisible] = useState<boolean>(false);

  const handleSelection = (memberId: string) => {
    if (selectedMemberId === memberId) {
      setSelectedMemberId('')
    } else {
      setSelectedMemberId(memberId);
    }
  }

  const deleteMemberQuery = useMutation({
    mutationFn: (id: string) => memberService.deleteMember(id),
    onSuccess: () => {
      info("Member deleted successfully");
      queryClient.invalidateQueries(["members"]);
    }
  })

  const handleDeleteMember = (id: string) => {
    deleteMemberQuery.mutate(id);
  }

  return (
    <>
      <div className="bg-white mb-3 p-3">
        <div className="justify-end flex">
          <div>
            <AdminButton title="Добави нов"
                         navigateTo={"/mth/admin/members/create"}/>
            <AdminButton title="Качи снимка"
                         disabled={selectedMemberId === ''}
                         onClick={() => setVisible(true)}/>
            <AdminButton title="Редакция"
                         disabled={selectedMemberId === ''}
                         navigateTo={"/mth/admin/members/edit/" + selectedMemberId}/>
            <AdminButton title="Изтриване"
                         disabled={selectedMemberId === ''}
                         onClick={() => handleDeleteMember(selectedMemberId)}/>
          </div>
        </div>
      </div>

      <div className="bg-white grid grid-cols-1 md:grid-cols-3">
        {members.data?.map((member: MemberType) => {
          return (
            <div key={member.id}>
              <div className="justify-center w-full flex mt-5">
                <ChangeTeamPosition memberId={member.id!}/>
              </div>
              <MemberAdmin key={member.id}
                           member={member}
                           isSelected={selectedMemberId === member.id}
                           handleSelection={(id: string) => handleSelection(id)}/>
            </div>
          )
        })}
      </div>

      <Dialog header="Качи снимка"
              visible={visible}
              style={{ width: '50vw' }}
              onHide={() => setVisible(false)}>
        <UploadPhotoMember memberId={selectedMemberId}/>
      </Dialog>
    </>
  );
};

export default AdminMembers;