import api from "../../../lib/api";
import {ENDPOINTS} from "../../../data/endpoints";
import {CreatePartnerType} from "../types/CreatePartnerType";
import {PartnerType} from "../types/PartnerType";

const createPartner = async (createPartnerType: CreatePartnerType) => {
  await api.post(ENDPOINTS.PARTNER, createPartnerType);
}

const getPartners = async () => {
  return await api.get<PartnerType[]>(ENDPOINTS.PARTNER);
}

const getPartner = async (id: string) => {
  return await api.get<PartnerType[]>(ENDPOINTS.PARTNER + "/" + id);
}

const editPartner = async (partner: PartnerType) => {
  await api.patch(ENDPOINTS.PARTNER + "/" + partner.id, partner);
}

const deletePartner = async (partnerId: string) => {
  await api.delete(ENDPOINTS.PARTNER + "/" + partnerId);
}

const partnersService = {
  createPartner,
  getPartners,
  getPartner,
  editPartner,
  deletePartner
};

export default partnersService;
