import NavbarItem from "./NavbarItem";
import Logo from "../Logo";
import NavbarDropdownItem from "./NavbarDropdownItem";
import {useState} from "react";

const Navbar = () => {
  const [isOpened, setOpened] = useState<boolean>(false);

  return (
    <div className={`sticky top-0 bg-white z-50`}>
      <div className="relative justify-between w-full md:flex px-6 py-2 mx-auto h-[5rem] md:h-autp">
        <div>
          <Logo/>
        </div>
        <div className="lg:flex lg:gap-5 invisible xl:visible">
          <NavbarItem label="Начало" to="/"/>
          <NavbarDropdownItem label="За нас" to="/"/>
          <NavbarItem label="Ученически календар" to="/calendar"/>
          <NavbarItem label="Частни уроци" to="/pricing"/>
          <NavbarItem label="Контакти" to="/contact"/>
        </div>
        {isOpened &&
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-50">
            <div className="visible bg-white p-2 xl:invisible absolute right-6 top-6 rounded">
              <button onClick={() => setOpened(!isOpened)}>
                <svg viewBox="0 0 24 24"
                     fill="none"
                     stroke="currentColor"
                     strokeWidth="2"
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     className="w-6 h-6 inline-block"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 18L20 18" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
                  <path d="M4 12L20 12" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
                  <path d="M4 6L20 6" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
            <div className="flex justify-center items-center h-full">
              <div className="bg-white rounded-lg p-4">
                <NavbarItem label="Начало"
                            to="/"
                            classOverride={"items-center flex px-2 text-sm font-extrabold hover:text-orange-400 transition-all"}
                            onClick={() => setOpened(false)}/>
                {/*<NavbarItem label="Кои сме ние?"*/}
                {/*            to="/"*/}
                {/*            classOverride={"items-center flex px-2 text-sm font-extrabold hover:text-orange-400 transition-all mt-1"}*/}
                {/*            onClick={() => setOpened(false)}/>*/}
                <NavbarItem label="Екип"
                            to="/team"
                            classOverride={"items-center flex px-2 text-sm font-extrabold hover:text-orange-400 transition-all mt-1"}
                            onClick={() => setOpened(false)}/>
                <NavbarItem label="Партньори"
                            to="/partners"
                            classOverride={"items-center flex px-2 text-sm font-extrabold hover:text-orange-400 transition-all mt-1"}
                            onClick={() => setOpened(false)}/>
                <NavbarItem label="Ученически календар"
                            to="/calendar"
                            classOverride={"items-center flex px-2 text-sm font-extrabold hover:text-orange-400 transition-all mt-1"}
                            onClick={() => setOpened(false)}/>
                <NavbarItem label="Частни уроци"
                            to="/pricing"
                            classOverride={"items-center flex px-2 text-sm font-extrabold hover:text-orange-400 transition-all mt-1"}
                            onClick={() => setOpened(false)}/>
                <NavbarItem label="Контакти"
                            to="/contact"
                            classOverride={"items-center flex px-2 text-sm font-extrabold hover:text-orange-400 transition-all mt-1"}
                            onClick={() => setOpened(false)}/>
              </div>
            </div>
          </div>
        }
        {!isOpened &&
          <div className="visible xl:invisible absolute right-6 top-6">
            <button onClick={() => setOpened(!isOpened)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                <path d="M3 12h18" />
                <path d="M3 6h18" />
                <path d="M3 18h18" />
              </svg>
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default Navbar;