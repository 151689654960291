import React, {useState} from "react";
import {Link, Outlet, useNavigate} from "react-router-dom";
import {removeWebStorage} from "../services/webStorage";

export default function IndexPage() {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  return (
    <>
      <div className="w-full h-full bg-gray-50">
        <div className="flex flex-no-wrap">
          {/* Sidebar starts */}
          <div className="absolute lg:relative w-64 h-screen shadow bg-white hidden lg:block">
            <ul aria-orientation="vertical" className="py-2 mt-3">
              <li
                className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mb-4 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                <Link to="/mth/admin/partners" className="flex items-center">
                  <span className="ml-2 text-orange-400 hover:text-orange-600 font-bold tracking-wide">Партньори</span>
                </Link>
              </li>
              <li
                className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mb-4 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                <Link to="/mth/admin/calendar" className="flex items-center">
                  <span className="ml-2 text-orange-400 hover:text-orange-600 font-bold tracking-wide">Ученически календар</span>
                </Link>
              </li>
              {/*<li*/}
              {/*  className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-4 mb-4 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">*/}
              {/*  <Link to="/mth/admin/dashboard" className="flex items-center">*/}
              {/*    <span className="ml-2 text-orange-400 hover:text-orange-600 font-bold tracking-wide">Dashboard</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li
                className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mb-4 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                <Link to="/mth/admin/services" className="flex items-center">
                  <span className="ml-2 text-orange-400 hover:text-orange-600 font-bold tracking-wide">Ценоразпис</span>
                </Link>
              </li>
              {/*<li*/}
              {/*  className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mb-4 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">*/}
              {/*  <Link to="/mth/admin/partners" className="flex items-center">*/}
              {/*    <span className="ml-2 text-orange-400 hover:text-orange-600 font-bold tracking-wide">Партньори</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                <Link to="/mth/admin/members" className="flex items-center">
                  <span className="ml-2 text-orange-400 hover:text-orange-600 font-bold tracking-wide">Екип</span>
                </Link>
              </li>
              <li className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none mt-4">
                <button className="flex items-center" onClick={() => {
                  removeWebStorage("token");
                  navigate("/");
                }}>
                  <span className="ml-2 text-orange-400 hover:text-orange-600 font-bold tracking-wide">Изход</span>
                </button>
              </li>
            </ul>
          </div>

          {/*Mobile responsive sidebar*/}
          <div className={show ? "w-full h-full absolute z-40  transform  translate-x-0 " : " w-full h-full absolute z-40  transform -translate-x-full"}
               id="mobile-nav">
            <div className="bg-gray-800 opacity-50 absolute h-full w-full lg:hidden" onClick={() => setShow(!show)}/>
            <div className="absolute z-40 sm:relative w-64 md:w-96 shadow pb-4 bg-white lg:hidden transition duration-150 ease-in-out h-full">
              <div className="flex flex-col justify-between h-full w-full">
                <div>
                  <div className="flex items-center justify-end ">
                    <div id="closeSideBar" className="flex items-center justify-center h-10 w-10" onClick={() => setShow(!show)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5"
                           stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z"/>
                        <line x1={18} y1={6} x2={6} y2={18}/>
                        <line x1={6} y1={6} x2={18} y2={18}/>
                      </svg>
                    </div>
                  </div>
                  <ul aria-orientation="vertical" className="py-6">
                    <li
                      className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-4 mb-4 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                      <div className="flex items-center">
                        <span className="ml-2 xl:text-base md:text-2xl text-base text-orange-400 hover:text-orange-600 font-bold tracking-wide">Dashboard</span>
                      </div>
                    </li>
                    <li
                      className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mb-4 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                      <div className="flex items-center">
                        <span className="ml-2 xl:text-base md:text-2xl text-base text-orange-400 hover:text-orange-600 font-bold tracking-wide">Ценоразпис</span>
                      </div>
                    </li>
                    <li
                      className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mb-4 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                      <div className="flex items-center">
                        <span className="ml-2 xl:text-base md:text-2xl text-base text-orange-400 hover:text-orange-600 font-bold tracking-wide">Партньори</span>
                      </div>
                    </li>
                    <li
                      className="pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                      <div className="flex items-center">
                        <span className="ml-2 xl:text-base md:text-2xl text-base text-orange-400 hover:text-orange-600 font-bold tracking-wide">Екип</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*Mobile responsive sidebar*/}
          {/* Sidebar ends */}
          <div className="w-full">
            {/* Navigation starts */}
            <nav className="h-16 flex items-center lg:items-stretch justify-end lg:justify-between bg-white shadow relative z-10">
              <div className="text-gray-600 mr-8 visible lg:hidden relative" onClick={() => setShow(!show)}>
                {show ? (
                  " "
                ) : (
                  <svg aria-label="Main Menu" aria-haspopup="true" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-menu cursor-pointer"
                       width={30} height={30} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <line x1={4} y1={8} x2={20} y2={8}/>
                    <line x1={4} y1={16} x2={20} y2={16}/>
                  </svg>
                )}
              </div>
            </nav>
            {/* Navigation ends */}
            {/* Remove class [ h-64 ] when adding a card block */}
            <div className="mx-auto p-5 h-64">
              {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
              <div className="w-full h-full">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
