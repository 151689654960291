import {createStore} from "../../../lib/store";
import {PartnerType} from "../types/PartnerType";

export interface IPartnerStore {
  partners: PartnerType[] | null,
  setPartners: (value: PartnerType[] | null) => void;
  selectedPartner: PartnerType | null,
  setSelectedPartner: (value: PartnerType | null) => void;
}

export const usePartnerStore = createStore("partner_store", (set, get) => ({
    partners: null,
    setPartners: (value: PartnerType[] | null) => set(() => ({partners: value})),
    selectedPartner: null,
    setSelectedPartner: (partner: PartnerType | null) => set((state: any) => ({selectedPartner: partner?.id === state.selectedPartner?.id ? null : partner})),
  })
)