import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {useRoutes} from "react-router-dom";
import {routes} from "./routes";
import {Suspense} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {ToastContainer} from "react-toastify";
import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

function App() {
  const routesContent = useRoutes(routes);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 3,
        staleTime: 5000,
      },
    },
  });

  return (
    <Suspense fallback={'loading'}>
      <QueryClientProvider client={queryClient}>
        {routesContent}
        <ToastContainer/>
        <ReactQueryDevtools/>
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
