import React, {useEffect, useState} from "react";
import {DateValueType} from "react-tailwindcss-datepicker/dist/types";
import {useEventStore} from "../../../features/event/stores/eventStore";
import {EventType} from "../../../features/event/types/EventType";
import {useDeleteEvent, useUpdateEvent} from "../../../features/event/hooks/eventHooks";
import Datepicker from "react-tailwindcss-datepicker";

const EditEvent = () => {

  const selectedEvent = useEventStore((state: any) => state.selectedEvent);
  const setSelectedEvent = useEventStore((state: any) => state.setSelectedEvent);

  const [name, setName] = useState("");
  const handleNameChange = (value: string) => setName(value);

  const [description, setDescription] = useState("");
  const handleDescriptionChange = (value: string) => setDescription(value);

  const [startDate, setStartDate] = useState<DateValueType>(null);
  const handleStartDateChange = (value: DateValueType) => setStartDate(value);

  const [endDate, setEndDate] = useState<DateValueType>(null);
  const handleEndDateChange = (value: DateValueType) => setEndDate(value);

  const editEventType: EventType = {
    id: selectedEvent?.id,
    name: name,
    startDate: startDate!?.startDate?.toString(),
    endDate: endDate!?.endDate?.toString(),
    description: description
  }
  const editEvent = useUpdateEvent(editEventType);
  const handleEditEvent = () => editEvent.mutate();

  const deleteEvent = useDeleteEvent(selectedEvent?.id);
  const handleDeleteEvent = () => {
    deleteEvent.mutate();
    setSelectedEvent(null);
  }

  useEffect(() => {
    if (selectedEvent !== null) {
      setName(selectedEvent.name);
      setDescription(selectedEvent.description);
      setStartDate({startDate: selectedEvent.startDate, endDate: selectedEvent.startDate});
      setEndDate({startDate: selectedEvent.endDate, endDate: selectedEvent.endDate});
    }
  }, [selectedEvent])

  if (selectedEvent === null) {
    return (
      <div className="text-center text-xs text-gray-600 pt-5">
        Няма избрано събитие
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Име</label>
          <input type="email" value={name} onChange={(e) => handleNameChange(e.target.value)}
                 className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-3">
          <label className="block text-xs font-medium text-gray-700">Кратко описание</label>
          <textarea rows={2} value={description} onChange={(e) => handleDescriptionChange(e.target.value)}
                    className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
        </div>

        <div className="mt-2">
          <label className="block text-xs font-medium text-gray-700">Започва от <span className="text-gray-400">(вкл.)</span></label>
          <Datepicker primaryColor={"red"}
                      value={startDate}
                      useRange={false}
                      placeholder="Старт"
                      asSingle={true}
                      startWeekOn={"mon"}
                      toggleClassName="hidden"
                      inputClassName="mt-1 w-full rounded-md border-gray-200 shadow-sm !bg-gray-100 px-3 !py-1.5 text-xs"
                      onChange={(date) => handleStartDateChange(date)}/>
        </div>

        <div className="mt-2">
          <label className="block text-xs font-medium text-gray-700">Завършва на<span className="text-gray-400">(вкл.)</span></label>
          <Datepicker primaryColor={"red"}
                      value={endDate}
                      useRange={false}
                      placeholder="Старт"
                      asSingle={true}
                      startWeekOn={"mon"}
                      toggleClassName="hidden"
                      inputClassName="mt-1 w-full rounded-md border-gray-200 shadow-sm !bg-gray-100 px-3 !py-1.5 text-xs"
                      onChange={(date) => handleEndDateChange(date)}/>
        </div>

        <div className="mt-2">
          <button type="button" onClick={handleEditEvent}
                  className="bg-orange-400 py-1.5 px-3  rounded text-xs text-white hover:bg-orange-300 transition ">
            Запис
          </button>
          <button type="button" onClick={handleDeleteEvent}
                  className="bg-orange-700 py-1.5 px-3 ml-2 rounded text-xs text-white hover:bg-orange-600 transition">
            Изтрий
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditEvent;