import {usePartnerStore} from "../../../features/partners/store/partnersStore";
import React, {useEffect, useState} from "react";
import {useDeletePartner, useEditPartner} from "../../../features/partners/hooks/partnersHooks";
import {PartnerType} from "../../../features/partners/types/PartnerType";

export const EditPartner = () => {

  const selectedPartner = usePartnerStore((state: any) => state.selectedPartner);
  const setSelectedPartner = usePartnerStore((state: any) => state.setSelectedPartner);

  const [name, setName] = useState("");
  const handleNameChange = (value: string) => setName(value);

  const [externalUrl, setExternalUrl] = useState("");
  const handleExternalUrlChange = (value: string) => setExternalUrl(value);

  const [shortDescription, setShortDescription] = useState("");
  const handleShortDescriptionChange = (value: string) => setShortDescription(value);

  const [description, setDescription] = useState("");
  const handleDescriptionChange = (value: string) => setDescription(value);

  const editPartnerType: PartnerType = {id: selectedPartner?.id, name: name, shortDescription: shortDescription, description: description, externalUrl: externalUrl}
  const editPartner = useEditPartner(editPartnerType);
  const handleEditPartner = () => editPartner.mutate();

  const deletePartner = useDeletePartner(selectedPartner?.id);
  const handleDeletePartner = () => {
    deletePartner.mutate();
    setSelectedPartner(null);
  }

  useEffect(() => {
    if (selectedPartner !== null) {
      setName(selectedPartner.name);
      setExternalUrl(selectedPartner.externalUrl);
      setShortDescription(selectedPartner.shortDescription);
      setDescription(selectedPartner.description);
    }
  }, [selectedPartner])

  if (selectedPartner === null) {
    return (
      <div className="text-center text-xs text-gray-600 pt-5">
        Няма избран партньор
      </div>
    );
  }

  return (
    <div>
      <div className="mt-3">
        <label className="block text-xs font-medium text-gray-700">Име</label>
        <input type="email" value={name} onChange={(e) => handleNameChange(e.target.value)}
               className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
      </div>

      <div className="mt-3">
        <label className="block text-xs font-medium text-gray-700">Препратка към външен адрес (линк)</label>
        <input type="email" value={externalUrl} onChange={(e) => handleExternalUrlChange(e.target.value)}
               className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
      </div>

      <div className="mt-3">
        <label className="block text-xs font-medium text-gray-700">Кратко описание</label>
        <textarea rows={2}
                  value={shortDescription} onChange={(e) => handleShortDescriptionChange(e.target.value)}
                  className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
      </div>

      <div className="mt-2">
        <label className="block text-xs font-medium text-gray-700">Дълго описание</label>
        <textarea rows={4}
                  value={description} onChange={(e) => handleDescriptionChange(e.target.value)}
                  className="mt-1 w-full rounded-md border-gray-200 shadow-sm bg-gray-100 px-3 py-1.5 text-xs"/>
      </div>

      <div className="mt-1">
        <button type="button" onClick={handleEditPartner}
                className="bg-orange-400 py-1.5 px-3 rounded text-xs text-white hover:bg-orange-300 transition">
          Запис
        </button>
        <button type="button" onClick={handleDeletePartner}
                className="bg-orange-700 py-1.5 px-3 ml-2 rounded text-xs text-white hover:bg-orange-600 transition">
          Изтрий
        </button>
      </div>
    </div>
  )
};

export default EditPartner;