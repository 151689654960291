import React from "react";
import {useChangeDetailPosition} from "../../features/member/hooks/memberHooks";

interface Props {
  member: string;
  detail: string;
}

export const ChangeTeamDetailPosition: React.FC<Props> = ({detail, member}) => {

  const useUp = useChangeDetailPosition(detail, "UP", member);
  const useDown = useChangeDetailPosition(detail, "DOWN", member);

  const handleUp = () => {
    useUp.mutate()
  }

  const handleDown = () => {
    useDown.mutate()
  }

  return (
    <div className="flex">
      <div className="cursor-pointer" onClick={handleDown}>
        <i className="pi pi-arrow-up text-orange-600 hover:text-orange-300 transition"/>
      </div>
      <div className="cursor-pointer ml-2 " onClick={handleUp}>
        <i className="pi pi-arrow-down text-orange-600 hover:text-orange-300 transition"/>
      </div>
    </div>
  )
}

export default ChangeTeamDetailPosition;