import {useGetPartners} from "../../../features/partners/hooks/partnersHooks";
import {PartnerType} from "../../../features/partners/types/PartnerType";
import {usePartnerStore} from "../../../features/partners/store/partnersStore";

export const Partners = () => {

  const partnersQuery = useGetPartners();
  const selectedPartner = usePartnerStore((state: any) => state.selectedPartner)
  const setSelectedPartner = usePartnerStore((state: any) => state.setSelectedPartner)

  return (
    <div>
      <table className="w-full">
        <thead>
        <tr>
          <td className="text-xs px-2 py-1 text-orange-600">Име</td>
          <td className="text-xs px-2 py-1 text-orange-600">Кратко описание</td>
          <td className="text-xs px-2 py-1 text-orange-600">Линк</td>
        </tr>
        </thead>
        <tbody>
        {partnersQuery.data?.map((element: PartnerType) => (
          <tr key={element.id}
              onClick={() => setSelectedPartner(element)}
              className={"hover:bg-gray-50 hover:cursor-pointer" + (selectedPartner?.id === element?.id ? " bg-gray-50" : "")}>
            <td className="text-xs px-2 py-1 text-gray-600">{element.name}</td>
            <td className="text-xs px-2 py-1 text-gray-600">{element.shortDescription}</td>
            <td className="text-xs px-2 py-1 text-gray-600">{element.externalUrl}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
};

export default Partners;