import {ENDPOINTS} from "../../../data/endpoints";
import {NewMemberDetailType} from "../types/memberDetailsType";
import {MemberType} from "../types/memberType";
import api from "../../../lib/api";
import {strict} from "assert";

const getAll = async () => {
  return await api.get<any>(ENDPOINTS.MEMBER);
};

const validateMember = async (id: string) => {
  return await api.get<any>(ENDPOINTS.VALIDATE_MEMBER + "/" + id);
};

const getMember = async (id: string) => {
 return await api.get<any>(ENDPOINTS.MEMBER + "/" + id);
};

const getMemberDetails = async (id: string) => {
  return await api.get<any>(ENDPOINTS.MEMBER + "/" + id + "/details")
}

const addDetail = async (memberDetail: NewMemberDetailType) => {
  return await api.post(ENDPOINTS.NEW_DETAIL, memberDetail)
}

const deleteDetail = async (detailId: string) => {
  return await api.post(ENDPOINTS.DELETE_DETAIL + "/" + detailId, {})
}

const updateMember = async (member: MemberType) => {
  return await api.post(ENDPOINTS.UPDATE_MEMBER, member);
}

const createMember = async (member: MemberType) => {
  return await api.post(ENDPOINTS.CREATE_MEMBER, member);
}

const deleteMember = async (id: string) => {
  await api.post(ENDPOINTS.DELETE_MEMBER, id);
}

const uploadPhoto = async (formData: FormData) => {
  await api.post(ENDPOINTS.UPLOAD_PHOTO, formData);
}

const changePosition = async (id: string, pos: string) => {
  return await api.post(ENDPOINTS.CHANGE_POSITION + id + "/" + pos);
}

const changeDetailPosition = async (id: string, pos: string) => {
  return await api.post(ENDPOINTS.CHANGE_DETAIL_POSITION + id + "/" + pos)
}

const memberService = {
  getAll,
  validateMember,
  getMember,
  getMemberDetails,
  addDetail,
  deleteDetail,
  updateMember,
  createMember,
  deleteMember,
  uploadPhoto,
  changePosition,
  changeDetailPosition
};

export default memberService;
